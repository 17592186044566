import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import ContactForm from "../components/contact-form.js"
import Footer from "../components/footer.js"

const Form = styled("div")`
	h2 {
		font-size: 2rem;
	}

	p {
		font-size: 18px;
	    margin-bottom: 40px;
	}

	form {
		input {
			height: 45px;
		    border: 1px solid;
		    padding: 0 20px 0 45px;
		    border-radius: 6px;
		    width: 100%;
		    border-color: rgba(37, 37, 37, .1);

		    &:focus {
		    	border-color: #76abe9;
    			outline: none;
		    }

		    &::placeholder {
		    	color: #808080;
		    }

		    &.error-field {
		    	border-color: red;
		    }
		}

		textarea {
			height: 150px;
		    border: 1px solid;
		    padding: 10px 45px;
		    border-radius: 6px;
		    width: 100%;
		    border-color: rgba(37, 37, 37, .1);

		    &:focus {
		    	border-color: #76abe9 !important;
    			outline: none;
		    }

		    &::placeholder {
		    	color: #808080;
		    }

		    &.error-field {
		    	border-color: red;
		    }
		}

		label {
			i {
			    background-size: contain !important;
			    background-position: center;
			    background-repeat: no-repeat;
			    min-width: 16px;
			    min-height: 16px;
			    left: 16px;
			    position: absolute;
			    opacity: .5;
			}

			&.name {
				i {
			    	background: url('../images/user.svg');
				}
			}

			&.email {
				i {
			    	background: url('../images/mail.svg');
				}
			}

			&.message {
				i {
			    	background: url('../images/comment.svg');
			    	margin-top: 15px;
			    }
			}
		}

		.error {
			position: absolute;
		    font-size: 12px;
		    color: red;
		    top: -6px;
		    left: 12px;
		    background: #fff;
		    padding: 0 5px;
		}
	}

	.ERROR {
		display: none;
	}
`

export default function Contact({data}) {
	return (
		<>
			<Seo title="Обратная связь | SleepAdvisor" description="Отправьте ваше сообщение или вопрос команде SleepAdvisor." />
			<Helmet>
				<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{"@type": "ListItem","position": 2,"name": "Обратная связь"}]}`}</script>
			</Helmet>
			<Menu />
			<div className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                <li className="breadcrumb-item active" aria-current="page">Обратная связь</li>
				                </ol>
				            </nav>
					    </div>
					</div>
					<h1>Обратная связь</h1>
				</div>
			</div>
			<Form className="py-6 md:py-16 max-w-[540px] md:max-w-[720px] lg:max-w-[750px] mx-auto px-4 sm:px-6">
				<div className="container text-center">
					<ContactForm />
				</div>
			</Form>
            <Footer />
	    </>
    )
}