import React from "react";

export default class MyForm extends React.Component {

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
      fields: {},
      errors: {}
    };
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
      formIsValid = false;
      errors["name"] = "Напишите Ваше имя";
    }

    //Email
    if(!fields["email"]){
      formIsValid = false;
      errors["email"] = "Напишите Вашу почту";
    }

    //Message
    if(!fields["message"]){
      formIsValid = false;
      errors["message"] = "Напишите Ваше сообщение";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(field, e){       
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
  }

  render() {
    const { status } = this.state;
    return (
      <div>
        <div className={status === "SUCCESS" ? `hidden`:``}>
          <h2 className="text-center">Отправьте ваше сообщение команде SleepAdvisor</h2>
          <p>Используйте контактную форму ниже, чтобы отправить свое сообщение или отправить нам по электронной почте</p>
        </div>
        <form action="https://formspree.io/f/xpzojrrj" method="POST" className={status === "SUCCESS" ? `hidden`:``} onSubmit= {this.submitForm.bind(this)}>
          <div className="grid grid-cols-2 gap-8 mb-[30px]">
            <label className="name relative flex items-center" htmlFor="name">
              <i />
              <input type="text" name="name" id="name" placeholder="Имя" onChange={this.handleChange.bind(this, "name")} className={this.state.errors["name"] === 'Напишите Ваше имя' ? `error-field`: ``} />
              <span className="error">{this.state.errors["name"]}</span>
            </label>
            <label className="email relative flex items-center" htmlFor="email">
              <i />
              <input type="text" name="email" id="email" placeholder="E-mail" onChange={this.handleChange.bind(this, "email")} className={this.state.errors["email"] === 'Напишите Вашу почту' ? `error-field`: ``} />
              <span className="error">{this.state.errors["email"]}</span>
            </label>
            <label className="message relative flex items-baseline col-span-2" htmlFor="message">
              <i />
              <textarea type="text" name="message" id="message" rows="5" placeholder="Сообщение" onChange={this.handleChange.bind(this, "message")} className={this.state.errors["message"] === 'Напишите Ваше сообщение' ? `error-field`: ``} />
              <span className="error">{this.state.errors["message"]}</span>
            </label>
          </div>
          <input type="text" name="page" readOnly hidden value="contact" />
          <button className="button button_primary button_s mt-7">Отправить</button>
          {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </form>
        <div className={status === "SUCCESS" ? `SUCCESS`:`ERROR`}>
          <h3>Спасибо!</h3>
          <p>Ваше сообщение отправлено</p>
        </div>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    if(this.handleValidation()){
      const form = ev.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          this.setState({ status: "SUCCESS" });
        } else {
          this.setState({ status: "ERROR" });
        }
      };
      xhr.send(data);
    }
  }
}
